import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  Typography,
  Container,
  makeStyles,
  createStyles,
  Grid,
  Theme,
} from '@material-ui/core'
import Button from '../Button/Button'
import Link from '../Link/Link'
import { LayoutStyle } from '../../typings/base'
import { useRouter } from 'next/router'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import CMSText, { isPrismicText, prismicText } from '../Global/CMSText'

const useStyles = makeStyles<Theme, LayoutStyle>((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      zIndex: 1,
    },
    isBG: {
      paddingTop: theme.sizes.footerBG,
      background: `url() repeat-x 0 0`,
      backgroundSize: `auto ${theme.sizes.footerBG}px`,
      '&::before': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: theme.sizes.footerBG,
        background:
          'linear-gradient(0deg, #E8E4DF 19%, rgba(232, 228, 223, 0) 50%, rgba(232, 228, 223, 0) 100%)',
      },
    },
    top: {
      position: 'relative',
      zIndex: 2,
      borderRadius: 0,
      paddingTop: 30,
      paddingBottom: 35,
      background: theme.extraColors.greyLight,
      textAlign: 'center',
      [theme.breakpoints.up('lg')]: {
        textAlign: 'left',
      },
      '&>.MuiContainer-root &>.hero__title': {
        marginBottom: theme.spacing(4),
      },
    },
    topRight: {
      [theme.breakpoints.up('lg')]: {
        textAlign: 'right',
      },
    },
    topTitle: {
      fontWeight: 400,
      color: theme.palette.primary.main,
    },
    topBtn: {
      marginTop: 20,
      [theme.breakpoints.up('lg')]: {
        marginTop: 0,
      },
    },
    bottom: {
      position: 'relative',
      zIndex: 1,
      marginTop: -8,
      paddingTop: 54,
      paddingBottom: 48,
      color: (LayoutStyle) =>
        LayoutStyle.color ? LayoutStyle.color : theme.palette.common.black,
      background: (LayoutStyle) =>
        LayoutStyle.backgroundColor
          ? LayoutStyle.backgroundColor
          : theme.extraColors.greyMedium,
      [theme.breakpoints.up('sm')]: {},
    },
    bottomLayout: {
      '&+div': {
        marginTop: theme.spacing(3),
      },
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
    bottomColumn: {
      '& a': {
        color: (LayoutStyle) => LayoutStyle.color && LayoutStyle.color,
        '&:hover, &:focus': {
          color: (LayoutStyle) =>
            LayoutStyle.hover.color && LayoutStyle.hover.color,
        },
        '&[aria-current=true]': {
          opacity: 0.5,
          pointerEvents: 'none',
        },
        '& button': {
          color: (LayoutStyle) => LayoutStyle.color,
          borderColor: (LayoutStyle) => LayoutStyle.color && LayoutStyle.color,
          backgroundColor: (LayoutStyle) =>
            LayoutStyle.color && LayoutStyle.color,
          '& .MuiButton-label': {
            color: (LayoutStyle) =>
              LayoutStyle.backgroundColor && LayoutStyle.backgroundColor,
          },
          '&:hover, &:focus': {
            backgroundColor: (LayoutStyle) =>
              LayoutStyle.hover.color && LayoutStyle.hover.color,
            borderColor: (LayoutStyle) =>
              LayoutStyle.hover.color && LayoutStyle.hover.color,
          },
        },
      },
      '&>.MuiButton-root': {
        margin: theme.spacing(1),
      },
      '&:nth-child(1)': {
        textAlign: 'center',
        '&>img': {
          width: 'auto',
          maxHeight: 100,
          [theme.breakpoints.up('lg')]: {
            marginRight: theme.spacing(3),
          },
        },
      },
      '&:nth-child(2)': {
        textAlign: 'center',
        margin: theme.spacing(0, 0, 2, 0),
        '& a+a': {
          marginLeft: theme.spacing(2),
        },
      },
      [theme.breakpoints.up('lg')]: {
        flex: '1',
        '&:nth-child(1)': {
          display: 'flex',
          alignItems: 'center',
        },
        '&:nth-child(2)': {
          textAlign: 'right',
          margin: 0,
        },
        '&:nth-child(3)': {
          textAlign: 'right',
        },
        '&>.MuiButton-root': {
          margin: theme.spacing(0, 0, 0, 1),
        },
      },
    },
    bottomTitle: {
      color: (LayoutStyle) => LayoutStyle.color && LayoutStyle.color,
    },
    social: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: theme.spacing(4, 0),
      [theme.breakpoints.up('lg')]: {
        justifyContent: 'flex-start',
        margin: theme.spacing(0),
      },
    },
    socialItem: {
      margin: theme.spacing(0, 1),
      display: 'inline-block',
      height: 24,
      width: 24,
      objectFit: 'contain',
      '&>img': {
        height: 24,
        width: 24,
        objectFit: 'contain',
      },
      color: (LayoutStyle) => LayoutStyle.color && LayoutStyle.color,
    },
    logo: {
      width: 'auto',
      maxHeight: 100,
      maxWidth: '100%',
    },
    copyright: {
      textAlign: 'center',
      color: (LayoutStyle) =>
        LayoutStyle.color ? LayoutStyle.color : theme.palette.common.black,
      background: (LayoutStyle) =>
        LayoutStyle.backgroundColor
          ? LayoutStyle.backgroundColor
          : theme.extraColors.greyMedium,
      padding: theme.spacing(3, 0),
    },
    copyrightText: {
      color: (LayoutStyle) =>
        LayoutStyle.color ? LayoutStyle.color : theme.palette.common.black,
      '& .paragraph-class': {
        margin: 0,
      },
      '& .link-class': {
        color: (LayoutStyle) =>
          LayoutStyle.color ? LayoutStyle.color : 'inherit',
      },
    },
  })
)

type Tprops = {
  doc?: any
  t?: any
  isBG?: boolean
  hideTop?: boolean
}

const Footer = ({ doc, hideTop, isBG, t }: Tprops) => {
  const background = doc?.data?.footer_background
  const font_color = doc?.data?.font_color
  const hover_color = doc?.data?.hover_color
  const LayoutStyle = {
    backgroundColor: background,
    color: font_color,
    hover: {
      color: hover_color,
    },
  }
  const classes = useStyles(LayoutStyle)
  const router = useRouter()
  const isTopBtn = isPrismicText(doc?.data.footer_top_link_label)
  const showTopFooter = doc?.data?.show_footer_top
  const { trackEvent } = useMatomo()
  const [registeredStoreSlug, setRegisteredStoreSlug] = useState('')

  useEffect(() => {
    typeof localStorage !== 'undefined' &&
      setRegisteredStoreSlug(
        JSON.parse(localStorage.getItem('selectedStore'))?.slug
      )
  }, [typeof localStorage !== 'undefined' && localStorage])

  return (
    <footer className={clsx('footer', classes.root, isBG && classes.isBG)}>
      {doc?.data?.footer_top_baseline !== 'undefined' &&
        !hideTop &&
        showTopFooter && (
          <div className={`footer__top ${classes.top}`}>
            <Container maxWidth="xl">
              <Grid container spacing={3} alignItems="center">
                <Grid
                  item
                  xs={12}
                  lg={isTopBtn ? 6 : 12}
                  xl={isTopBtn ? 8 : 12}
                >
                  <Typography
                    variant="h5"
                    component="div"
                    className={clsx(classes.topTitle, 'footer__top__title')}
                  >
                    {prismicText(
                      doc?.data?.footer_top_baseline,
                      t('texts:general:footer_top_baseline')
                    )}
                  </Typography>
                </Grid>
                {isTopBtn && (
                  <Grid item xs={12} lg={6} xl={4} className={classes.topRight}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        trackEvent({
                          category: `Footer`,
                          href: `/${doc?.data?.footer_top_link}`,
                          action: `Top link footer to ${doc?.data?.footer_top_link}`,
                          name: `${router.pathname}`,
                        })
                      }
                      target={doc?.data.footer_top_link?.target}
                      href={doc?.data?.footer_top_link?.url}
                      className={`footer__topbtn ${classes.topBtn}`}
                    >
                      <CMSText
                        asText
                        data={doc?.data?.footer_top_link_label}
                        defaultText={t('texts:general:footer_top_link_label')}
                      />
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Container>
          </div>
        )}
      <div
        className={`footer__bottom ${classes.bottom}`}
        id="basket_visibility_threshold"
      >
        <Container maxWidth="xl">
          <div className={`footer__bottomRow1 ${classes.bottomLayout}`}>
            <div className={`footer__bottomRow1Col ${classes.bottomColumn}`}>
              {doc?.data?.footer_logo.url && (
                <Link href="/">
                  <a>
                    <img
                      className={`footer__logo ${classes.logo}`}
                      src={doc?.data?.footer_logo.url}
                      alt={prismicText(doc?.data?.footer_logo.alt, '')}
                    />
                  </a>
                </Link>
              )}
              <Typography
                variant="h4"
                component="div"
                className={`footer__baseline ${classes.bottomTitle}`}
              >
                {prismicText(
                  doc?.data?.footer_tagline,
                  t('texts:general:footer_tagline')
                )}
              </Typography>
            </div>
            <div className={`footer__bottomRow1Col ${classes.bottomColumn}`}>
              {isPrismicText(doc?.data?.contact_mail?.url) && (
                <Link href={doc?.data?.contact_mail?.url || '/contact'}>
                  <a>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        trackEvent({
                          category: `Footer`,
                          href: `/${doc?.data?.contact_mail.url}`,
                          action: `footer to ${doc?.data?.contact_mail.url}`,
                          name: `${router.pathname}`,
                        })
                      }
                      className={classes.topBtn}
                    >
                      {prismicText(
                        doc?.data?.contact_us_button,
                        t('texts:general:contact_us_button')
                      )}
                    </Button>
                  </a>
                </Link>
              )}
              {doc?.data?.faq_link?.url && (
                <Link href={doc?.data?.faq_link?.url || '/faq'}>
                  <a>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        trackEvent({
                          category: `Footer`,
                          href: `/faq`,
                          action: 'footer to faq',
                          name: `${router.pathname}`,
                        })
                      }
                      className={classes.topBtn}
                      aria-current={router.pathname === '/faq'}
                      disabled={router.pathname === '/faq'}
                    >
                      {t('texts:general:FAQ')}
                    </Button>
                  </a>
                </Link>
              )}
            </div>
          </div>
          <div className={`footer__bottomRow2 ${classes.bottomLayout}`}>
            <div className={`footer__bottomRow2Col ${classes.bottomColumn}`}>
              <div className={`footer__social ${classes.social}`}>
                {doc?.data?.social_networks.map((socialNetwork, index) => (
                  <a
                    key={index}
                    href={socialNetwork.link.url}
                    target="_blank"
                    onClick={() =>
                      trackEvent({
                        category: 'Footer',
                        href: `/${socialNetwork.link.url}`,
                        action: `footer to ${socialNetwork.link.url}`,
                        name: `${router.pathname}`,
                      })
                    }
                    rel="noopener noreferrer"
                    className={classes.socialItem}
                  >
                    <img
                      src={socialNetwork.icon.url}
                      alt={prismicText(socialNetwork?.label)}
                    />
                  </a>
                ))}
              </div>
            </div>
            <div
              className={clsx(
                'footer__bottomRow2Col',
                'footer__menu',
                classes.bottomColumn
              )}
            >
              {typeof tC !== 'undefined' && typeof window !== 'undefined' && (
                <Link href={'#'}>
                  <a
                    target={'_self'}
                    onClick={() => tC?.privacyCenter?.showPrivacyCenter()}
                  >
                    {t('texts:general:handle_cookies')}
                  </a>
                </Link>
              )}
              {doc?.data?.footer_menu.length !== 0 &&
                doc?.data?.footer_menu.map((item, index) => {
                  const itemType = item?.link?.uid !== undefined
                  const itemSrc = itemType
                    ? `/pages/${item.link.uid}`
                    : item.link.url
                  const itemUrl =
                    (itemSrc?.includes('cgl') ||
                      itemSrc?.includes('mentions-legales')) &&
                    typeof registeredStoreSlug !== 'undefined' &&
                    registeredStoreSlug !== '' &&
                    registeredStoreSlug !== null
                      ? `${itemSrc}-${registeredStoreSlug}`
                      : `${itemSrc}`

                  return itemType ? (
                    <Link key={index} href={itemUrl}>
                      <a
                        id={`footer-link-${index}`}
                        onClick={() =>
                          trackEvent({
                            category: `Footer`,
                            href: itemUrl,
                            action: `Link to ${itemUrl}`,
                            name: `${router.pathname}`,
                          })
                        }
                        aria-current={
                          router.asPath !== null && router.asPath === itemUrl
                        }
                      >
                        {prismicText(item?.label)}
                      </a>
                    </Link>
                  ) : (
                    item.link.url && (
                      <Link key={index} href={itemUrl}>
                        <a
                          target={
                            item.link?.target === '_blank' ? '_blank' : '_self'
                          }
                          onClick={() =>
                            trackEvent({
                              category: `Footer`,
                              href: itemUrl,
                              action: `Link to ${itemUrl}`,
                              name: `${router.pathname}`,
                            })
                          }
                          aria-current={
                            router.asPath !== null && router.asPath === itemUrl
                          }
                        >
                          {prismicText(item?.label)}
                        </a>
                      </Link>
                    )
                  )
                })}
            </div>
          </div>
        </Container>
      </div>
      {isPrismicText(doc?.data?.copyright) && (
        <div className={`footer__copyright ${classes.copyright}`}>
          <Container maxWidth="xl">
            <Typography
              component={'div'}
              className={`footer-copyright__text ${classes.copyrightText}`}
            >
              <CMSText
                defaultText={t('texts:general:copyright')}
                data={doc?.data?.copyright}
              />
            </Typography>
          </Container>
        </div>
      )}
    </footer>
  )
}

export default Footer
